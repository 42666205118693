<template>
    <div class="w-full bg-gray-light">
        <div
            class="mx-2 my-4 modal-edit"
            v-bind:class="{ 'edit-shadow': isEdit }"
        >
            <div
                class="flex flex-wrap justify-between items-center align-baseline border-b-2 border-blue-base mb-5"
            >
                <h1 class="text-blue-base uppercase pt-5">
                    Informações Jurídicas
                </h1>
                <div
                    v-if="!$store.getters.isOrganization"
                    class="form-new-blue"
                    v-on:click="isEdit = !isEdit"
                >
                    <div>
                        <span v-if="isEdit">Fechar</span>
                        <span v-else>Editar</span>
                    </div>
                </div>
            </div>
            <form v-on:submit.prevent="onFormSubmit()">
                <div class="form-group">
                    <div class="row" v-if="!editable">
                        <div class="w-100 alert alert-primary">
                            Para editar essas informações, faça login com seu
                            CPF possuindo perfil de gestor.
                        </div>
                    </div>
                    <div class="text-xs">
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-1/3 pr-2 mb-4">
                                <span class="form-label text-gray-dark text-xs"
                                    >CPF / CNPJ</span
                                >
                                <the-mask
                                    v-if="isEdit"
                                    class="form-control rounded py-2 h-auto"
                                    :mask="[
                                        '###.###.###-##',
                                        '##.###.###/####-##'
                                    ]"
                                    v-model="user.document"
                                    disabled="disabled"
                                />
                                <span v-else> {{ user.document }}</span>
                            </div>

                            <div class="w-full lg:w-1/3 px-2 mb-4">
                                <span class="form-label text-gray-dark text-xs"
                                    >Nome Fantasia</span
                                >
                                <input
                                    v-if="isEdit"
                                    :disabled="!editable"
                                    v-model="legalInformation.business_name"
                                    class="form-control rounded py-2 h-auto"
                                />
                                <span v-else>
                                    {{ legalInformation.business_name }}</span
                                >
                            </div>

                            <div class="w-full lg:w-1/3 pl-2 mb-4">
                                <span class="form-label text-gray-dark text-xs" id="label-know-name" title="Nome mais conhecido da empresa, geralmente utilizado em sua logomarca">
                                    Nome mais conhecido
                                </span>
                                <input
                                    v-if="isEdit"
                                    :disabled="!editable"
                                    v-model="legalInformation.know_name"
                                    class="form-control rounded py-2 h-auto"
                                    title="Nome mais conhecido da empresa, geralmente utilizado em sua logomarca"
                                />
                                <span v-else for="label-know-name" title="Nome mais conhecido da empresa, geralmente utilizado em sua logomarca">
                                    {{ legalInformation.know_name }}</span
                                >
                            </div>

                            <div class="w-full lg:w-1/3 pr-2 mb-4">
                                <span class="form-label text-gray-dark text-xs"
                                    >Inscrição Estadual</span
                                >
                                <input
                                    v-if="isEdit"
                                    :disabled="!editable"
                                    v-model="
                                        legalInformation.state_registration
                                    "
                                    class="form-control rounded py-2 h-auto"
                                />
                                <span v-else>
                                    {{
                                        legalInformation.state_registration
                                    }}</span
                                >
                            </div>

                            <div class="w-full lg:w-1/3 px-2 mb-4">
                                <span class="form-label text-gray-dark text-xs"
                                    >Inscrição Municipal</span
                                >
                                <input
                                    v-if="isEdit"
                                    :disabled="!editable"
                                    v-model="
                                        legalInformation.county_registration
                                    "
                                    class="form-control rounded py-2 h-auto"
                                />
                                <span v-else>
                                    {{
                                        legalInformation.county_registration
                                    }}</span
                                >
                            </div>

                            <div class="w-full lg:w-1/3 pl-2 mb-4">
                                <span class="form-label text-gray-dark text-xs"
                                    >Referência</span
                                >
                                <input
                                    v-if="isEdit"
                                    :disabled="!editable"
                                    v-model="legalInformation.reference"
                                    class="form-control rounded py-2 h-auto"
                                />
                                <span v-else>
                                    {{ legalInformation.reference }}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-content-center">
                    <button
                        class="form-submit w-full sm:w-auto"
                        v-if="editable && isEdit"
                    >
                        Salvar
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import RegistryService from "@/core/services/RegistryService";

@Component({
    components: {
        "the-mask": TheMask
    }
})
export default class LegalInformation extends RegistryService {
    @Prop()
    user;

    @Prop()
    editable;

    constructor() {
        super();
        this.errors = null;
        this.isEdit = false;
        this.legalInformation = {
            business_name: "",
            know_name: "",
            state_registration: "",
            county_registration: "",
            reference: ""
        };
    }

    async mounted() {
        this.legalInformation = await this.getInfoOrganization(this.user.id);
    }

    async onFormSubmit() {
        let info = await this.putRegistryLegalInformation(
            this.user.id,
            this.legalInformation
        );

        if (info.errors) this.errors = info.errors;
        this.isEdit = false;
    }
}
</script>
