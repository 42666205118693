<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import SystemInfo from "@/core/components/portlets/SystemInfo";
import CarrierInformation from "@/domains/transport/portlets/CarrierInformation";

export default {
    components: {
        Breadcrumbs,
        SystemInfo,
        CarrierInformation
    },
    data: () => {
        return {
            title: "Viagem",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Viagem", path: "travel.index" }
            ],
            alerts: [
                "Declare as viagens que sua frota irá realizar.",
                "Atente para as condições de alterações das viagens acessando a Central de Ajuda.",
                "O Sistema não permite declarar viagem se o seu registro estiver vencido.",
                "Para realizar a viagem, lembre-se de carregar em seu veículo a autorização de viagem e o CRC correspondente.",
                "Motorista, baixe a nova versão do SCmobiDocs para portar a documentação da licença em seu smartphone.",
                "Transportadoras, ao alterar a placa do veículo para o padrão MERCOSUL, é OBRIGATÓRIO abrir um processo para a GEROT realizar a atualização do cadastro."
            ]
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <portal-target name="modals" multiple />
        <div class="grid gap-6 px-2 my-4 lg:max-w-5xl sm:px-6">
            <Breadcrumbs :crumbs="crumbs" />
            <SystemInfo :alerts="alerts" class="mt-2" />
            <CarrierInformation />
            <div>
                <a
                    target="_blank"
                    title="Ajuda sobre Viagens"
                    href="https://ajuda.sie.sc.gov.br/categorias-artigos/viagem/"
                    class="inline-flex items-center space-x-2 text-sm underline text-cool-gray-500 hover:text-blue-light"
                >
                    <svg
                        class="w-4 h-4 fill-current"
                        viewBox="0 0 384 512"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"
                        ></path>
                    </svg>
                    <div>
                        Ajuda sobre Viagens
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
