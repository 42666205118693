<script>
import { TheMask } from "vue-the-mask";
import Icon from "@/core/components/Icon";
const Password = () => import("vue-password-strength-meter");

export default {
    components: {
        Password,
        TheMask,
        Icon
    },
    data: function() {
        return {
            title: "Cadastro",
            name: "",
            document: "",
            phone: "",
            email: "",
            email_confirmation: "",
            password: "",
            password_confirmation: "",
            status: "idle",
            errors: null
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    },
    methods: {
        register() {
            this.$Progress.start();
            this.errors = null;
            this.status = "pending";

            this.$axios
                .post("/api/registry/user", {
                    name: this.name,
                    document: this.document,
                    phone: this.phone,
                    email: this.email,
                    email_confirmation: this.email_confirmation,
                    password: this.password,
                    password_confirmation: this.password_confirmation
                })
                .then(response => {
                    this.$toast.success({
                        title: "OK",
                        message: response.data.success
                    });
                    this.status = "finished";
                    this.$Progress.finish();
                    this.$router.push({ name: "login" });
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.errors = e.response.data.errors;
                    } else {
                        this.$toast.error({
                            message:
                                e.response.data.message || e.response.data.error
                        });
                    }
                    this.status = "rejected";
                    this.$Progress.finish();
                });
        }
    }
};
</script>

<template>
    <div
        class="h-auto min-h-screen lg:flex lg:flex-col lg:justify-center bg-blue-background sm:bg-blue-background md:bg-image-none md:bg-gray-lighter"
    >
        <div
            class="max-w-md py-8 mx-auto my-4 bg-white shadow font-display xl:my-6 xl:py-4 sm:max-w-xl lg:max-w-4xl xl:max-w-6xl"
        >
            <div class="flex">
                <div
                    class=" hidden lg:flex flex-col lg:border-r-2 lg:border-blue-dark lg:p-15 justify-between lg:mt-1 lg:px-16 lg:mr-8 lg:w-1/2"
                >
                    <img
                        class="lg:px-20 p-10 xl:px-28 "
                        src="@/core/assets/images/marca_sie.png"
                        alt="Governo de Santa Catarina"
                    />
                    <img
                        class="lg:mb-6 pb-20 xl:px-16"
                        src="@/core/assets/images/logo_sc_mobi_novo.png"
                        alt="SCmobi"
                    />
                  
                </div>
                <div class="w-full lg:w-1/2 lg:mr-8">
                    <div
                        class="flex justify-center mx-3 border-b-2 border-blue-dark lg:hidden"
                    >
                        <img
                            class="w-10/12 pb-4 mt-2"
                            src="@/core/assets/images/logo_sc_mobi_novo.png"
                            alt="SCmobi"
                        />
                    </div>
                    <h1
                        class="mt-4 mb-3 text-xl font-medium leading-tight text-center uppercase lg:mt-8 lg:mb-8 text-blue-dark"
                    >
                        Sistema de Transporte<br />Intermunicipal de Passageiros
                    </h1>
                    <form @submit.prevent="register" class="p-4">
                        <label
                            class="mt-4 mb-2 text-sm font-medium leading-tight uppercase lg:w-1/2 text-blue-dark"
                        >
                            Nome Completo
                        </label>
                        <input
                            v-model="name"
                            class="block w-full px-4 py-2 leading-normal rounded-sm appearance-none placeholder-blue-lighter bg-gray-lighter focus:outline-none focus:shadow-outline"
                            type="text"
                            placeholder="Nome Completo"
                            required
                        />
                        <div v-if="errors && errors.name">
                            <p
                                v-for="(error, index) in errors.name"
                                v-bind:key="index"
                                class="pl-2 text-xs italic text-red-base"
                            >
                                {{ error }}
                            </p>
                        </div>
                        <div class="lg:flex lg:my-2">
                            <div class="lg:w-1/2 lg:mr-2">
                                <label
                                    class="mt-4 mb-2 text-sm font-medium leading-tight uppercase text-blue-dark"
                                >
                                    CPF
                                </label>
                                <TheMask
                                    required
                                    mask="###.###.###-##"
                                    v-model="document"
                                    placeholder="000.000.000-00"
                                    class="block w-full px-4 py-2 leading-normal rounded-sm appearance-none placeholder-blue-lighter bg-gray-lighter focus:outline-none focus:shadow-outline"
                                />
                                <div v-if="errors && errors.document">
                                    <p
                                        v-for="(error,
                                        index) in errors.document"
                                        v-bind:key="index"
                                        class="pl-2 text-xs italic text-red-base"
                                    >
                                        {{ error }}
                                    </p>
                                </div>
                            </div>
                            <div class="lg:w-1/2">
                                <label
                                    class="mt-4 mb-2 text-sm font-medium leading-tight uppercase text-blue-dark"
                                >
                                    Telefone
                                </label>
                                <TheMask
                                    required
                                    :mask="[
                                        '(##) ####-####',
                                        '(##) #####-####'
                                    ]"
                                    v-model="phone"
                                    placeholder="(48) 99999-9999"
                                    class="block w-full px-4 py-2 leading-normal rounded-sm appearance-none placeholder-blue-lighter bg-gray-lighter focus:outline-none focus:shadow-outline"
                                />
                                <div v-if="errors && errors.phone">
                                    <p
                                        v-for="(error, index) in errors.phone"
                                        v-bind:key="index"
                                        class="pl-2 text-xs italic text-red-base"
                                    >
                                        {{ error }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label
                                class="mt-6 mb-2 text-sm font-medium leading-tight uppercase text-blue-dark"
                            >
                                E-mail
                            </label>
                            <input
                                required
                                v-model="email"
                                class="block w-full px-4 py-2 leading-normal rounded-sm appearance-none placeholder-blue-lighter bg-gray-lighter focus:outline-none focus:shadow-outline"
                                type="email"
                                placeholder="email@dominio.com"
                            />
                            <div v-if="errors && errors.email">
                                <p
                                    v-for="(error, index) in errors.email"
                                    v-bind:key="index"
                                    class="pl-2 text-xs italic text-red-base"
                                >
                                    {{ error }}
                                </p>
                            </div>
                        </div>
                        <div class="lg:mt-2">
                            <label
                                class="mt-6 mb-2 text-sm font-medium leading-tight uppercase text-blue-dark"
                            >
                                Confirmar E-mail
                            </label>
                            <input
                                v-model="email_confirmation"
                                required
                                class="block w-full px-4 py-2 leading-normal rounded-sm appearance-none placeholder-blue-lighter bg-gray-lighter focus:outline-none focus:shadow-outline"
                                type="email"
                                placeholder="email@dominio.com"
                            />
                        </div>
                        <div class="lg:flex lg:mt-2">
                            <div class="lg:w-1/2 lg:mr-2">
                                <label
                                    class="mt-6 mb-2 text-sm font-medium leading-tight uppercase text-blue-dark"
                                >
                                    SENHA
                                </label>
                                <input
                                    v-model="password"
                                    required
                                    class="block w-full px-4 py-2 leading-normal rounded-sm appearance-none placeholder-blue-lighter bg-gray-lighter focus:outline-none focus:shadow-outline"
                                    type="password"
                                    placeholder="******"
                                />
                                <div v-if="errors && errors.password">
                                    <p
                                        v-for="(error,
                                        index) in errors.password"
                                        v-bind:key="index"
                                        class="pl-2 text-xs italic text-red-base"
                                    >
                                        {{ error }}
                                    </p>
                                </div>
                            </div>
                            <div class="lg:w-1/2">
                                <label
                                    class="mt-6 mb-2 text-sm font-medium leading-tight uppercase text-blue-dark"
                                >
                                    Confirmar Senha
                                </label>
                                <input
                                    v-model="password_confirmation"
                                    required
                                    class="block w-full px-4 py-2 leading-normal rounded-sm appearance-none placeholder-blue-lighter bg-gray-lighter focus:outline-none focus:shadow-outline"
                                    type="password"
                                    placeholder="******"
                                />
                            </div>
                        </div>
                        <div class="lg:mt-1">
                            <password
                                v-model="password"
                                :strength-meter-only="true"
                            />
                        </div>
                        <div class="mt-4 xl:flex">
                            <button
                                type="submit"
                                :disabled="status === 'pending'"
                                class="text-xs font-medium uppercase btn btn-bluegradient xl:w-1/2"
                            >
                                Solicitar Cadastro
                            </button>
                            <router-link
                                :to="{ name: 'login' }"
                                class="xl:w-1/2"
                            >
                                <button
                                    type="button"
                                    class="my-2 text-xs font-medium uppercase shadow btn btn:focus btn-gray btn-gray:hover btn-gray:focus btn-gray:active xl:ml-1"
                                >
                                    Já tem cadastro? Entre aqui
                                </button>
                            </router-link>
                        </div>
                        <a
                            href="https://ajuda.sie.sc.gov.br"
                            target="_blank"
                            class="flex items-center justify-center mt-2 text-sm font-medium normal-case text-blue-dark md:mt-4 lg:mb-4"
                        >
                            <Icon
                                icon="help"
                                class="h-6 mx-2 fill-current text-blue-dark"
                            />Saiba mais sobre a conta SCmobi
                        </a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
